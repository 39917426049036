import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import PageHeader from '../components/PageHeader';

import stare from '../images/stare.jpg';
import feel from '../images/feel.jpg';
import pullThrough from '../images/pull-through.jpg';

const Home = () => {
  return (
    <>
      <PageHeader
        button={
          <Link className="btn btn-green" to="/boek-een-bosbad">
            BOEK NU EEN BOSBAD
          </Link>
        }
        imageClass="main"
        title={
          <>
            Bosbaden
            <br />
            Nederland
          </>
        }
      />
      <section className="grey-section">
        <div className="content content-column">
          <h3>Bosbadgidsen Nederland</h3>
          <p className="margin-content">
            Ben je toe aan ontspanning, en tijd voor jezelf. Even afstand nemen van de dagelijkse hectiek van het leven
            en tot rust komen in de natuur? Hieronder vind je de agenda van de bosbaden die de verenigde bosbadgidsen
            aanbieden.
          </p>
          <Link className="btn btn-green" to="/boek-een-bosbad">
            LEES MEER
          </Link>
        </div>
      </section>
      <section className="set-height">
        <div className="content margin">
          <Container>
            <Row>
              <Col className="padding-column" md="6" sm="12">
                <h5>Gidsen aangesloten bij BBGN</h5>
                <p className="mt-3">
                  Als je kiest voor een bosbad met een bosbadgids die is aangesloten bij de Branchevereniging voor
                  Bosbadgidsen, kies je voor kwaliteit.
                </p>
                <img alt="feel" className="index-image-one" src={feel} />
              </Col>
              <Col className="grey right-padding-column">
                <div className="logo"></div>
                <h3>Wat bosbaden met je doet?</h3>
                <p>
                  Bosbaden is niet alleen goed voor jouw gezondheid en mentale welzijn. Het kan ook een transformatief
                  karakter hebben en bijdragen aan persoonlijke ontwikkeling.
                </p>
                <Link className="btn btn-green" to="/boek-een-bosbad">
                  LEES MEER
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section className="green-section">
        <div className="content content-column white">
          <h3 className="mb-5">Waarom zou je bosbaden?</h3>
          <Container>
            <Row className="card-row">
              <Col>
                <div className="card-white">
                  <div className="card-icon">
                    <FontAwesomeIcon icon={icon({ name: 'check' })} />
                  </div>
                  <div>
                    Een van de belangrijkste voordelen van bosbaden is de vermindering van stress en angst. Dit kan
                    leiden tot een verhoogde ontspanning, vermindering van depressie en een verbetering van de stemming.
                  </div>
                </div>
              </Col>
              <Col>
                <div className="card-white">
                  <div className="card-icon">
                    <FontAwesomeIcon icon={icon({ name: 'check' })} />
                  </div>
                  <div>
                    Een van de belangrijkste voordelen van bosbaden is de vermindering van stress en angst. Dit kan
                    leiden tot een verhoogde ontspanning, vermindering van depressie en een verbetering van de stemming.
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="card-row">
              <Col>
                <div className="card-white">
                  <div className="card-icon">
                    <FontAwesomeIcon icon={icon({ name: 'check' })} />
                  </div>
                  <div>
                    Een van de belangrijkste voordelen van bosbaden is de vermindering van stress en angst. Dit kan
                    leiden tot een verhoogde ontspanning, vermindering van depressie en een verbetering van de stemming.
                  </div>
                </div>
              </Col>
              <Col>
                <div className="card-white">
                  <div className="card-icon">
                    <FontAwesomeIcon icon={icon({ name: 'check' })} />
                  </div>
                  <div>
                    Een van de belangrijkste voordelen van bosbaden is de vermindering van stress en angst. Dit kan
                    leiden tot een verhoogde ontspanning, vermindering van depressie en een verbetering van de stemming.
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="card-row">
              <Col>
                <div className="card-white">
                  <div className="card-icon">
                    <FontAwesomeIcon icon={icon({ name: 'check' })} />
                  </div>
                  <div>
                    Een van de belangrijkste voordelen van bosbaden is de vermindering van stress en angst. Dit kan
                    leiden tot een verhoogde ontspanning, vermindering van depressie en een verbetering van de stemming.
                  </div>
                </div>
              </Col>
              <Col>
                <div className="card-white">
                  <div className="card-icon">
                    <FontAwesomeIcon icon={icon({ name: 'check' })} />
                  </div>
                  <div>
                    Een van de belangrijkste voordelen van bosbaden is de vermindering van stress en angst. Dit kan
                    leiden tot een verhoogde ontspanning, vermindering van depressie en een verbetering van de stemming.
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section>
        <div className="content mb-5">
          <Container className="quote-container">
            <Row>
              <Col lg="7">
                <img alt="pull-through" className="image-square" src={pullThrough} />
              </Col>
              <Col lg="5">
                <div className="grey-card">
                  <h5>The Most Beautiful Forests</h5>
                  <p>
                    “I declare this world is so beautiful that I can hardly believe it exists.” The beauty of nature can
                    have a profound effect upon our senses, those gateways from the outer world to the inner, whether it
                    results in disbelief...
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <div className="quote">
                  <span>I declare this world is so beautiful that I can hardly believe it exists</span>
                  <div className="quote-symbol">
                    <FontAwesomeIcon icon={icon({ name: 'quote-left' })} />
                  </div>
                </div>
              </Col>
              <Col lg="8">
                <img alt="stare" className="image-wide" src={stare} />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Home;
